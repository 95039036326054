html {
    scroll-behavior: smooth;
}

body {
    background: #040e10;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #050e10;
}
::-webkit-scrollbar-thumb {
    background: #da3949;
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
    background: #da3949d3;
}